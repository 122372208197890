<template>
  <div v-bind:class="{ 'custom-section': !integration }">
    <div>
      <div v-show="!integration" class="mx-0 vx-row">
        <vs-col vs-w="12">
          <h2>
            <span class="float-left mr-2 mb-base">{{ title }}</span>
            <vs-icon
              class="small float-left"
              :class="titleClass"
              :icon="icon"
              icon-pack="feather"
            ></vs-icon>
            <div class="float-right">
              <vs-button
                v-tooltip="
                  lang.chatTransitionSettings.settings.test[languageSelected]
                "
                size="small"
                radius
                type="border"
                icon="icon-play"
                icon-pack="feather"
                @click="test"
              ></vs-button>
              <AttentionRequestTransition
                ref="testPrompt"
                :key="testKey"
                :params="value"
                :title="title"
                :test="true"
              />
            </div>
          </h2>
        </vs-col>
      </div>

      <div v-if="!hasSurvey" class="vx-row mx-0">
        <vs-col vs-w="12">
          <vs-row class="mx-0" vs-align="center">
            <vs-col vs-w="12" class="chip-message__name">
              <p class="txt-base text-dark mb-5">
                {{
                  lang.chatTransitionSettings.settings.tagging[languageSelected]
                }}
              </p>
              <div class="vx-row mx-0">
                <vs-col vs-w="6">
                  <ul>
                    <li>
                      <vs-radio v-model="value.tagging" vs-value="disable">
                        {{
                          lang.chatTransitionSettings.settings.radios.disabled[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                    <li>
                      <vs-radio v-model="value.tagging" vs-value="optional">
                        {{
                          lang.chatTransitionSettings.settings.radios.optional[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                    <li>
                      <vs-radio v-model="value.tagging" vs-value="required">
                        {{
                          lang.chatTransitionSettings.settings.radios.required[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                  </ul>
                </vs-col>
                <vs-col vs-w="6">
                  <ul>
                    <li>
                      <vs-radio
                        v-model="value.taggingType"
                        vs-value="single"
                        :disabled="value.tagging === 'disable'"
                      >
                        {{
                          lang.chatTransitionSettings.settings.radios.single[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                    <li>
                      <vs-radio
                        v-model="value.taggingType"
                        vs-value="multiple"
                        :disabled="value.tagging === 'disable'"
                      >
                        {{
                          lang.chatTransitionSettings.settings.radios.multiple[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                  </ul>
                </vs-col>
              </div>
              <div class="new-chip mt-5">
                <div class="input-message">
                  <vs-input
                    :placeholder="
                      lang.chatTransitionSettings.settings.taggingPlaceholder[
                        languageSelected
                      ]
                    "
                    v-model="newTag"
                    @keyup.enter="addTag"
                    :disabled="value.tagging === 'disable'"
                  />
                </div>
                <div>
                  <vs-button
                    @click="addTag"
                    color="primary"
                    type="filled"
                    icon="add"
                    :disabled="value.tagging === 'disable'"
                  ></vs-button>
                </div>
              </div>
            </vs-col>
          </vs-row>
          <vs-row class="mt-5">
            <vs-col vs-w="12">
              <span
                class="truncate"
                :key="index"
                v-for="(item, index) in value.tags"
              >
                <vs-chip
                  :color="value.tagging !== 'disable' ? 'primary' : 'default'"
                  @click="removeTag(index)"
                  :closable="value.tagging !== 'disable'"
                  >{{ item }}</vs-chip
                >
              </span>
            </vs-col>
          </vs-row>

          <vs-row vs-align="center">
            <vs-col vs-w="12" class="chip-message__name">
              <p class="txt-base text-dark mb-5 mt-5">
                {{
                  lang.chatTransitionSettings.settings.comment[languageSelected]
                }}
              </p>
              <div class="vx-row mx-0">
                <vs-col vs-w="12">
                  <ul>
                    <li>
                      <vs-radio v-model="value.comment" vs-value="disable">
                        {{
                          lang.chatTransitionSettings.settings.radios.disabled[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                    <li>
                      <vs-radio v-model="value.comment" vs-value="optional">
                        {{
                          lang.chatTransitionSettings.settings.radios.optional[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                    <li>
                      <vs-radio v-model="value.comment" vs-value="required">
                        {{
                          lang.chatTransitionSettings.settings.radios.required[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                  </ul>
                </vs-col>
              </div>
            </vs-col>
          </vs-row>

          <vs-row vs-align="center">
            <vs-col vs-w="12" class="chip-message__name">
              <p class="txt-base text-dark mb-5 mt-5">
                {{
                  lang.chatTransitionSettings.settings.defaultTransitionMessage[
                    languageSelected
                  ]
                }}
              </p>
              <div class="vx-row mx-0">
                <vs-col vs-w="12">
                  <ul>
                    <li>
                      <vs-radio
                        v-model="value.transitionMessage"
                        vs-value="disable"
                      >
                        {{
                          lang.chatTransitionSettings.settings.radios.disabled[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                    <li>
                      <vs-radio
                        v-model="value.transitionMessage"
                        vs-value="optional"
                      >
                        {{
                          lang.chatTransitionSettings.settings.radios.optional[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                    <li>
                      <vs-radio
                        v-model="value.transitionMessage"
                        vs-value="required"
                      >
                        {{
                          lang.chatTransitionSettings.settings.radios.required[
                            languageSelected
                          ]
                        }}
                      </vs-radio>
                    </li>
                  </ul>
                </vs-col>
              </div>
              <div class="vx-row mx-0 mt-5">
                <vs-col vs-w="12">
                  <vs-textarea
                    v-model="value.defaultTransitionMessage"
                    :placeholder="
                      lang.chatTransitionSettings.settings
                        .defaultTransitionMessagePlaceholder[languageSelected]
                    "
                    :disabled="value.transitionMessage === 'disable'"
                  />
                </vs-col>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
      </div>

      <div v-else class="vx-row mx-0 survey-row">
        <vs-col vs-w="12">
          <vs-row vs-w="12" class="vx-row mx-0 mt-2 survey-wrapper">
            <!-- <vs-divider position="left">
                Surveys
            </vs-divider>-->

            <!-- <div class="vx-row">
            <vs-col vs-w="12">
              <p class="txt-base text-dark mb-5 survey-text">
                {{
                lang.chatTransitionSettings.settings.surveyTitle[
                languageSelected
                ]
                }}
              </p>
            </vs-col>
            </div>-->

            <vs-col class="pl-0 col-100 survey-checkbox" vs-w="12">
              <vs-checkbox color="primary" v-model="surveyValues.surveyEnabled">
                <!-- @change="toggleSurvey" -->
                {{ textSurveyEnabled }}
              </vs-checkbox>
            </vs-col>
            <vs-col
              class="pl-0 col-100"
              vs-w="12"
              v-if="surveyValues.surveyEnabled"
            >
              <div id="div-with-loading">
                <multiselect
                  :disabled="!surveyValues.surveyEnabled"
                  label="name"
                  :allow-empty="false"
                  v-model="surveySel"
                  :options="surveys"
                  track-by="_id"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  @select="emitSurveyValue"
                  placeholder
                >
                  <span slot="noResult">
                    {{
                      lang.chatTransitionSettings.settings.noResult[
                        this.languageSelected
                      ]
                    }}
                  </span>
                </multiselect>
                <KonaAlert
                  v-if="showAlert"
                  icon="AlertCircleIcon"
                  bg-color="rgba(var(--vs-warning), 0.15)"
                  color="rgba(var(--vs-warning), 1)"
                  size="small"
                >
                  {{
                    lang.chatTransitionSettings.settings.alertNoPd[
                      languageSelected
                    ]
                  }}
                </KonaAlert>
              </div>
              <!-- <Hsm /> -->
            </vs-col>
          </vs-row>
          <div v-if="surveyValues.surveyEnabled">
            <Confirmation
              v-bind:class="{ 'no-card': integration }"
              class="confirmation-box"
              :integration="integration"
              :confirmation.sync="surveyValues.surveyConfirmation.active"
              :firstMessage.sync="surveyValues.surveyConfirmation.firstMessage"
              :rejectedMessage.sync="
                surveyValues.surveyConfirmation.rejectedMessage
              "
              :resendMessage.sync="
                surveyValues.surveyConfirmation.resendMessage
              "
            ></Confirmation>
            <Hsm
              v-if="!integration"
              class="hsm-box mt-5"
              :variables.sync="surveyValues.surveyHsm.parameters"
              :languageCode.sync="surveyValues.surveyHsm.languageCode"
              :elementName.sync="surveyValues.surveyHsm.elementName"
              :namespace.sync="surveyValues.surveyHsm.namespace"
            ></Hsm>
          </div>
        </vs-col>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import services from '../../survey-maker/services'
import AttentionRequestTransition from '../../attention-requests/transition/AttentionRequestTransition'
import KonaAlert from '@/components/KonaAlert.vue'

export default {
  components: {
    AttentionRequestTransition,
    //Hsm: () => import('../../diffusions/diffusion/components/Hsm.vue'),
    Hsm: () => import('../../survey-send-view/channels/Components/Hsm'),
    Multiselect: () => import('vue-multiselect'),
    KonaAlert,
    Confirmation: () =>
      import('../../survey-send-view/channels/Components/Confirmation.vue')
  },
  props: {
    integration: {
      type: Boolean,
      default: false
    },
    name: String,
    title: {
      type: String
    },
    icon: {
      type: String
    },
    titleClass: {
      type: String
    },
    params: {
      type: Object
    },
    hasSurvey: {
      type: Boolean,
      default: false
    },
    onlySurvey: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      surveySel: {},
      surveyAll: [],
      hasActiveVersion: true,
      newTag: '',
      value: {
        tagging: 'disable',
        taggingType: 'single',
        tags: [],
        comment: 'disable',
        transitionMessage: 'disable',
        defaultTransitionMessage: ''
      },
      surveyValues: {
        surveyEnabled: false,
        survey: '',
        surveyHsm: {
          namespace: '',
          languageCode: '',
          elementName: '',
          parameters: []
        },
        surveyConfirmation: {
          active: false,
          rejectedMessage: '',
          resendMessage: '',
          firstMessage: ''
        }
      },
      testKey: new Date().getTime(),
      surveys: []
    }
  },
  computed: {
    ...mapState([
      'languageSelected',
      'lang',
      'session',
      'chatTransitionSettings'
    ]),
    textSurveyEnabled() {
      let toReturn = this.lang.chatTransitionSettings.settings.surveyEnabled[
        this.languageSelected
      ]
      if (this.integration) {
        toReturn = this.lang.chatTransitionSettings.settings
          .surveyEnabledIntegration[this.languageSelected]
      }
      return toReturn
    },
    showAlert() {
      return this.hasActiveVersion == false
    },
    surveySelected() {
      const surveys = this.surveyAll
      const elS = Object.assign(surveys).find(el => {
        return el._id == this.surveyValues.survey
      })
      return elS || null
    }
  },
  watch: {
    surveySelected() {
      // services.getSurvey(newVal._id).then(el => {
      //   console.log('RESULT SURVEY::', el)
      // })
    },
    'surveyValues.surveyHsm': {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler(newValue) {
        console.log('SurveyHsm New Value')
        console.log(newValue)
      }
    },
    'surveyValues.survey'(newVal) {
      if (newVal && this.name == 'survey') {
        this.onSurveySelected({ id: newVal })
      }
    },
    'surveyValues.surveyConfirmation': {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler(newValue) {
        console.log('surveyConfirmation New Value')
        console.log(newValue)
      }
    }
  },
  methods: {
    emitSurveyValue(survey) {
      this.surveyValues.survey = survey._id
    },
    async onSurveySelected({ id }) {
      services
        .hasActiveVersion(id)
        .then(res => {
          if (res) {
            const { hasActiveVersion } = res.data
            this.hasActiveVersion = hasActiveVersion
          }
        })
        .catch(e => {
          console.log('Error with API::', e)
        })
    },
    addTag() {
      if (this.newTag && this.value.tags.indexOf(this.newTag.trim()) === -1) {
        this.value.tags.push(this.newTag.trim())
      }
      this.newTag = ''
      this.testKey = new Date().getTime()
    },
    removeTag(index) {
      this.value.tags.splice(index, 1)
      this.testKey = new Date().getTime()
    },
    verifyConfirmation() {
      const {
        active,
        rejectedMessage,
        resendMessage,
        firstMessage
      } = this.surveyValues.surveyConfirmation
      if (active == false) return true
      if (!rejectedMessage) return false
      if (!resendMessage) return false
      if (!firstMessage) return false

      return true
    },
    getValue(isBotIntegration) {
      if (this.name == 'survey') {
        if (this.surveyValues.surveyEnabled) {
          if (!this.surveyValues.survey) {
            this.$vs.notify({
              title: this.lang.botMaker.errorTitle[this.languageSelected],
              text: this.lang.chatTransitionSettings.settings.noSurveyFail[
                this.languageSelected
              ],
              color: 'danger'
            })
            return { error: 'Missing Survey' }
          }
          const vConf = this.verifyConfirmation()
          if (!vConf) {
            this.$vs.notify({
              title: this.lang.botMaker.errorTitle[this.languageSelected],
              text: this.lang.chatTransitionSettings.settings.confirmationFail[
                this.languageSelected
              ],
              color: 'danger'
            })
            return { error: 'Missing Confirmation' }
          }
        }
        if (this.surveyValues.surveyEnabled == undefined)
          this.surveyValues.surveyEnabled = false
        if (isBotIntegration) {
          delete this.surveyValues.surveyHsm
        }
        delete this.surveyValues._id
        return this.surveyValues
      } else {
        return this.value
      }
    },
    test() {
      this.$refs.testPrompt.open()
    },
    syncSurveys() {
      const id = this.params.survey
      if (id) {
        this.surveyValues.survey = id
        this.surveySel = this.surveys.find(el => {
          return el._id == id
        })
      }
    },
    async getSurveys() {
      this.$vs.loading()
      try {
        const result = await services.getSurveys()
        this.surveyAll = result.data
        this.surveys = result.data.map(element => ({
          _id: element._id,
          service: element.service._id,
          name: element.service.name,
          _createdAt: element._createdAt
        }))
        this.syncSurveys()
      } catch (error) {
        // TODO: MOSTRAR UN ERROR AL USUARIO.
        console.error('error', error)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  async created() {
    console.log('PARAMS GOT::', this.params)
    if (this.name == 'survey') {
      await this.getSurveys()
      this.surveyValues.surveyEnabled = this.params.surveyEnabled
      if (this.params.survey) this.surveyValues.survey = this.params.survey

      if (this.params.surveyHsm)
        this.surveyValues.surveyHsm = this.params.surveyHsm

      if (this.params.surveyConfirmation)
        this.surveyValues.surveyConfirmation = this.params.surveyConfirmation
    } else {
      this.value.tagging = this.params.tagging
      this.value.taggingType = this.params.taggingType
      this.value.tags = this.params.tags
        ? JSON.parse(JSON.stringify(this.params.tags))
        : []
      this.value.comment = this.params.comment
      this.value.transitionMessage = this.params.transitionMessage
      this.value.defaultTransitionMessage = this.params.defaultTransitionMessage
    }
  }
}
</script>

<style lang="scss">
.chip-message {
  background: white;
  &__name {
    .new-chip {
      display: flex;
      flex-grow: 1;
      .input-message {
        margin-right: 10px;
        width: 100%;
        .vs-con-input-label {
          width: 100%;
        }
      }
      .vs-button {
        min-width: 38px;
        min-height: 38px;
      }
    }
    .vs-card--content {
      margin-bottom: 0;
    }
    .survey-text {
      padding: 0 15px;
    }
    .survey-wrapper {
      display: flex;
      align-items: center;
      padding: 0 0px;
      margin: 0 !important;
      .vs-col {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .con-vs-checkbox {
        margin-left: 0;
      }
    }
  }
}
.col-100 {
  #div-with-loading {
    width: 100%;
  }
  .con-select {
    width: 100%;
  }
  &.survey-checkbox {
    padding-bottom: 15px;
  }
  padding-bottom: 20px;
  display: flex;
  width: 100%;
}

.hsm-box {
  .vx-card__collapsible-content .vx-card__body {
    padding-top: 1rem;
  }
}

.confirmation-box {
  .vx-row.mb-base {
    margin-bottom: 0.7rem !important;
  }
  .vx-card__collapsible-content .vx-card__body {
    padding-top: 1rem;
  }
}

.vx-card.no-card {
  .vx-card__header,
  .vx-card__body {
    padding-left: 0;
  }
  box-shadow: none;
  background-color: transparent;
}
</style>
