var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'custom-section': !_vm.integration }},[_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.integration),expression:"!integration"}],staticClass:"mx-0 vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('h2',[_c('span',{staticClass:"float-left mr-2 mb-base"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('vs-icon',{staticClass:"small float-left",class:_vm.titleClass,attrs:{"icon":_vm.icon,"icon-pack":"feather"}}),_vm._v(" "),_c('div',{staticClass:"float-right"},[_c('vs-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.lang.chatTransitionSettings.settings.test[_vm.languageSelected]
              ),expression:"\n                lang.chatTransitionSettings.settings.test[languageSelected]\n              "}],attrs:{"size":"small","radius":"","type":"border","icon":"icon-play","icon-pack":"feather"},on:{"click":_vm.test}}),_vm._v(" "),_c('AttentionRequestTransition',{key:_vm.testKey,ref:"testPrompt",attrs:{"params":_vm.value,"title":_vm.title,"test":true}})],1)],1)])],1),_vm._v(" "),(!_vm.hasSurvey)?_c('div',{staticClass:"vx-row mx-0"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-row',{staticClass:"mx-0",attrs:{"vs-align":"center"}},[_c('vs-col',{staticClass:"chip-message__name",attrs:{"vs-w":"12"}},[_c('p',{staticClass:"txt-base text-dark mb-5"},[_vm._v("\n              "+_vm._s(_vm.lang.chatTransitionSettings.settings.tagging[_vm.languageSelected])+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"vx-row mx-0"},[_c('vs-col',{attrs:{"vs-w":"6"}},[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-value":"disable"},model:{value:(_vm.value.tagging),callback:function ($$v) {_vm.$set(_vm.value, "tagging", $$v)},expression:"value.tagging"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.disabled[
                          _vm.languageSelected
                        ])+"\n                    ")])],1),_vm._v(" "),_c('li',[_c('vs-radio',{attrs:{"vs-value":"optional"},model:{value:(_vm.value.tagging),callback:function ($$v) {_vm.$set(_vm.value, "tagging", $$v)},expression:"value.tagging"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.optional[
                          _vm.languageSelected
                        ])+"\n                    ")])],1),_vm._v(" "),_c('li',[_c('vs-radio',{attrs:{"vs-value":"required"},model:{value:(_vm.value.tagging),callback:function ($$v) {_vm.$set(_vm.value, "tagging", $$v)},expression:"value.tagging"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.required[
                          _vm.languageSelected
                        ])+"\n                    ")])],1)])]),_vm._v(" "),_c('vs-col',{attrs:{"vs-w":"6"}},[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-value":"single","disabled":_vm.value.tagging === 'disable'},model:{value:(_vm.value.taggingType),callback:function ($$v) {_vm.$set(_vm.value, "taggingType", $$v)},expression:"value.taggingType"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.single[
                          _vm.languageSelected
                        ])+"\n                    ")])],1),_vm._v(" "),_c('li',[_c('vs-radio',{attrs:{"vs-value":"multiple","disabled":_vm.value.tagging === 'disable'},model:{value:(_vm.value.taggingType),callback:function ($$v) {_vm.$set(_vm.value, "taggingType", $$v)},expression:"value.taggingType"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.multiple[
                          _vm.languageSelected
                        ])+"\n                    ")])],1)])])],1),_vm._v(" "),_c('div',{staticClass:"new-chip mt-5"},[_c('div',{staticClass:"input-message"},[_c('vs-input',{attrs:{"placeholder":_vm.lang.chatTransitionSettings.settings.taggingPlaceholder[
                      _vm.languageSelected
                    ],"disabled":_vm.value.tagging === 'disable'},on:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTag($event)}},model:{value:(_vm.newTag),callback:function ($$v) {_vm.newTag=$$v},expression:"newTag"}})],1),_vm._v(" "),_c('div',[_c('vs-button',{attrs:{"color":"primary","type":"filled","icon":"add","disabled":_vm.value.tagging === 'disable'},on:{"click":_vm.addTag}})],1)])])],1),_vm._v(" "),_c('vs-row',{staticClass:"mt-5"},[_c('vs-col',{attrs:{"vs-w":"12"}},_vm._l((_vm.value.tags),function(item,index){return _c('span',{key:index,staticClass:"truncate"},[_c('vs-chip',{attrs:{"color":_vm.value.tagging !== 'disable' ? 'primary' : 'default',"closable":_vm.value.tagging !== 'disable'},on:{"click":function($event){_vm.removeTag(index)}}},[_vm._v(_vm._s(item))])],1)}))],1),_vm._v(" "),_c('vs-row',{attrs:{"vs-align":"center"}},[_c('vs-col',{staticClass:"chip-message__name",attrs:{"vs-w":"12"}},[_c('p',{staticClass:"txt-base text-dark mb-5 mt-5"},[_vm._v("\n              "+_vm._s(_vm.lang.chatTransitionSettings.settings.comment[_vm.languageSelected])+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"vx-row mx-0"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-value":"disable"},model:{value:(_vm.value.comment),callback:function ($$v) {_vm.$set(_vm.value, "comment", $$v)},expression:"value.comment"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.disabled[
                          _vm.languageSelected
                        ])+"\n                    ")])],1),_vm._v(" "),_c('li',[_c('vs-radio',{attrs:{"vs-value":"optional"},model:{value:(_vm.value.comment),callback:function ($$v) {_vm.$set(_vm.value, "comment", $$v)},expression:"value.comment"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.optional[
                          _vm.languageSelected
                        ])+"\n                    ")])],1),_vm._v(" "),_c('li',[_c('vs-radio',{attrs:{"vs-value":"required"},model:{value:(_vm.value.comment),callback:function ($$v) {_vm.$set(_vm.value, "comment", $$v)},expression:"value.comment"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.required[
                          _vm.languageSelected
                        ])+"\n                    ")])],1)])])],1)])],1),_vm._v(" "),_c('vs-row',{attrs:{"vs-align":"center"}},[_c('vs-col',{staticClass:"chip-message__name",attrs:{"vs-w":"12"}},[_c('p',{staticClass:"txt-base text-dark mb-5 mt-5"},[_vm._v("\n              "+_vm._s(_vm.lang.chatTransitionSettings.settings.defaultTransitionMessage[
                  _vm.languageSelected
                ])+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"vx-row mx-0"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-value":"disable"},model:{value:(_vm.value.transitionMessage),callback:function ($$v) {_vm.$set(_vm.value, "transitionMessage", $$v)},expression:"value.transitionMessage"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.disabled[
                          _vm.languageSelected
                        ])+"\n                    ")])],1),_vm._v(" "),_c('li',[_c('vs-radio',{attrs:{"vs-value":"optional"},model:{value:(_vm.value.transitionMessage),callback:function ($$v) {_vm.$set(_vm.value, "transitionMessage", $$v)},expression:"value.transitionMessage"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.optional[
                          _vm.languageSelected
                        ])+"\n                    ")])],1),_vm._v(" "),_c('li',[_c('vs-radio',{attrs:{"vs-value":"required"},model:{value:(_vm.value.transitionMessage),callback:function ($$v) {_vm.$set(_vm.value, "transitionMessage", $$v)},expression:"value.transitionMessage"}},[_vm._v("\n                      "+_vm._s(_vm.lang.chatTransitionSettings.settings.radios.required[
                          _vm.languageSelected
                        ])+"\n                    ")])],1)])])],1),_vm._v(" "),_c('div',{staticClass:"vx-row mx-0 mt-5"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-textarea',{attrs:{"placeholder":_vm.lang.chatTransitionSettings.settings
                      .defaultTransitionMessagePlaceholder[_vm.languageSelected],"disabled":_vm.value.transitionMessage === 'disable'},model:{value:(_vm.value.defaultTransitionMessage),callback:function ($$v) {_vm.$set(_vm.value, "defaultTransitionMessage", $$v)},expression:"value.defaultTransitionMessage"}})],1)],1)])],1)],1)],1):_c('div',{staticClass:"vx-row mx-0 survey-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-row',{staticClass:"vx-row mx-0 mt-2 survey-wrapper",attrs:{"vs-w":"12"}},[_c('vs-col',{staticClass:"pl-0 col-100 survey-checkbox",attrs:{"vs-w":"12"}},[_c('vs-checkbox',{attrs:{"color":"primary"},model:{value:(_vm.surveyValues.surveyEnabled),callback:function ($$v) {_vm.$set(_vm.surveyValues, "surveyEnabled", $$v)},expression:"surveyValues.surveyEnabled"}},[_vm._v("\n              "+_vm._s(_vm.textSurveyEnabled)+"\n            ")])],1),_vm._v(" "),(_vm.surveyValues.surveyEnabled)?_c('vs-col',{staticClass:"pl-0 col-100",attrs:{"vs-w":"12"}},[_c('div',{attrs:{"id":"div-with-loading"}},[_c('multiselect',{attrs:{"disabled":!_vm.surveyValues.surveyEnabled,"label":"name","allow-empty":false,"options":_vm.surveys,"track-by":"_id","searchable":true,"close-on-select":true,"show-labels":false,"placeholder":""},on:{"select":_vm.emitSurveyValue},model:{value:(_vm.surveySel),callback:function ($$v) {_vm.surveySel=$$v},expression:"surveySel"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("\n                  "+_vm._s(_vm.lang.chatTransitionSettings.settings.noResult[
                      this.languageSelected
                    ])+"\n                ")])]),_vm._v(" "),(_vm.showAlert)?_c('KonaAlert',{attrs:{"icon":"AlertCircleIcon","bg-color":"rgba(var(--vs-warning), 0.15)","color":"rgba(var(--vs-warning), 1)","size":"small"}},[_vm._v("\n                "+_vm._s(_vm.lang.chatTransitionSettings.settings.alertNoPd[
                    _vm.languageSelected
                  ])+"\n              ")]):_vm._e()],1)]):_vm._e()],1),_vm._v(" "),(_vm.surveyValues.surveyEnabled)?_c('div',[_c('Confirmation',{staticClass:"confirmation-box",class:{ 'no-card': _vm.integration },attrs:{"integration":_vm.integration,"confirmation":_vm.surveyValues.surveyConfirmation.active,"firstMessage":_vm.surveyValues.surveyConfirmation.firstMessage,"rejectedMessage":_vm.surveyValues.surveyConfirmation.rejectedMessage,"resendMessage":_vm.surveyValues.surveyConfirmation.resendMessage},on:{"update:confirmation":function($event){_vm.$set(_vm.surveyValues.surveyConfirmation, "active", $event)},"update:firstMessage":function($event){_vm.$set(_vm.surveyValues.surveyConfirmation, "firstMessage", $event)},"update:rejectedMessage":function($event){_vm.$set(_vm.surveyValues.surveyConfirmation, "rejectedMessage", $event)},"update:resendMessage":function($event){_vm.$set(_vm.surveyValues.surveyConfirmation, "resendMessage", $event)}}}),_vm._v(" "),(!_vm.integration)?_c('Hsm',{staticClass:"hsm-box mt-5",attrs:{"variables":_vm.surveyValues.surveyHsm.parameters,"languageCode":_vm.surveyValues.surveyHsm.languageCode,"elementName":_vm.surveyValues.surveyHsm.elementName,"namespace":_vm.surveyValues.surveyHsm.namespace},on:{"update:variables":function($event){_vm.$set(_vm.surveyValues.surveyHsm, "parameters", $event)},"update:languageCode":function($event){_vm.$set(_vm.surveyValues.surveyHsm, "languageCode", $event)},"update:elementName":function($event){_vm.$set(_vm.surveyValues.surveyHsm, "elementName", $event)},"update:namespace":function($event){_vm.$set(_vm.surveyValues.surveyHsm, "namespace", $event)}}}):_vm._e()],1):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }